/* LoginForm.css */
.login-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
}

.login-form input {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
  border: 1px solid #ccc;
}

.login-form button {
  width: 100% !important;
  background-color: #1a73e8;
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  cursor: pointer;
}
.login-form button:hover {
  background-color: #5194ee;
  transform: none;
}

.error {
  color: red;
}

footer {
  max-width: 600px;
  display: block;
}