span.user-email, span.user-name, span.user-credits {
    width: 200px;
    display: inline-block;
}

/* Table styles */
table {
    width: 100%;
    border-collapse: collapse;
}
  
th, td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #ddd;
}
  
th {
    background-color: #f2f2f2;
}
  
tr:hover {
    background-color: #f5f5f5;
}
  
.caption {
    font-weight: bold;
    margin-bottom: 10px;
}
  
.highlight {
    background-color: #ffe8cc;
}

td.user-actions button {
    margin: 3px;
    padding: 10px;
    border-radius: 20px;
}