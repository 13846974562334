/* RegistrationForm.css */

.registration-form {
  max-width: 500px;
  margin: 20px auto;
  padding: 1rem;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.registration-form h2 {
  margin-bottom: 1rem;
  font-size: 1.5rem;
  text-align: center;
}

.registration-form label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: bold;
}

.registration-form input {
  width: 100%;
  padding: 0.5rem;
  margin-bottom: 1rem;
  border: 1px solid #ccc;
  border-radius: 10px;
}
.registration-form button {
  margin: auto;
  display: block;
  width: 400px;
  padding: 0.5rem;
  background-color: #1a73e8;
  border: 2px solid black;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.2s;
}

.registration-form button:hover {
  background-color: #5194ee;
}

.registration-form input {
  width: 100%;
  padding: 0.5rem;
  margin-bottom: 1rem;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-sizing: border-box;
}

.registration-form img {
  margin: auto;
  display: block;
}

footer {
  margin: auto;
  display: block;
  font-style: italic;
}

.google-reg-form {
  margin-left: auto;
  margin-right: auto;
  width: 400px;
}