body, html {
  margin: 0;
  width: 100vw;
  height: 100vh;
}

/* right sidebar */
.premium {
  text-shadow: 2px 2px 4px rgba(240, 208, 3, 0.2);
  font-weight: bold;
}

.layout-container {
  display: flex;
  height: 100vh;
}

.main {
  flex: 1;
}

.aside {
  width: 31%;
  margin: 0;
  padding: 0;
  background-color: #eee;
  border: 2px solid #ccc; overflow: hidden;
}

.aside, .history, .history-container {
  max-height: 100vh !important;
}

.user-history {
  margin-top: 0px;
  padding-right: 0px !important;
}
.history-container h2 {
  font-size: .9em;
}
.history-container {
  overflow: auto;
  padding-right: 0px !important;
  margin: 0px;
  font-size: .8em;
}
.history-item {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-wrap: break-word;  
  padding-right: 0px !important;
  padding-left: 3px;
  padding-top: 7px;
  vertical-align: middle;
  font-weight: normal;
  height: 10vh;
  cursor: crosshair;
  width: 30vw;
}
.history-item-title-parent {
  position: relative;
  display: block;
  margin-top:20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 28vw !important;
}
.history-item-delete-button {
  vertical-align: sub;
  float: right;
  padding-right: 10px;
  cursor: pointer;
  position: relative;
}
.history-item:hover {
  background-color: #ffcc00;
}
.history-item:before{
  background-size:cover;
  position:absolute;
  width:20px;
  height:20px;
  margin-left:-20px;
  line-height: 1;
  vertical-align: middle;
}
.history-date {
  font-style: italic;
  position: relative;
  margin-top: -50px;
  margin-left: 30px;
}

/* Add this to your CSS file */
.sidebar {
  float: left;
  margin-left: 2vw;
  width: 30vw;
  height: 40vh;
}

div.user-bar .usernameLink:hover {
  text-decoration: underline;
}

.query-footer {
  position: fixed;
  font-size: 0.8em !important;
  width: 90%;
  margin: 0 auto;
  text-align: center;
}

.title {
  font-weight: bold;
  padding: 5px;
  font-size: 80%;
}

.output-container {
  display: block;
  margin: 3px auto;
  background-color: #ffffff !important;
  border:rgba(0, 0, 0, .5) 2px solid;
  border-radius: 6px;
  height: 65vh;
  overflow: auto;
}
.report-output {
  float: right;
  margin: 5px;
  margin-right: 5vw;
  width: 30vw;
  height: 60vh;
}

.whybotsreporttitle {
  font-weight: bold;
  margin-left: -20px !important;
}
div.Query form {
  height: 65vh;
}
div.Query form textarea,
div.Query form input {
  margin-left: 5%;
  height: 14vh;
  border:rgba(0, 0, 0, .5) 2px solid;
}
.additional-input {
  height: 23vh !important;
}
.title-input {
  height: 8vh !important;
}
.form-container {
  width: 100%;
  max-width: 600px; /* Adjust this value based on your design */
  margin: 0 auto;
}

.form-row {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 10px; /* Adjust this value based on your design */
}

.Query label {
  font-weight: bold;
}
.form-container label {
  white-space: nowrap;
  display: inline-block;
  margin-right: 10px;
  padding-left: 25px;
  font-weight: bold;
}

.form-container span {
  display: block;
  padding-right: 15px;
  width: 77%;
  text-align: left;
}

textarea, input {
  padding: 2px;
  font-size: 1em !important;
  width: 28vw;
  margin: 0 auto;
  display: block;
  text-align: center;
  border: 1px solid #ccc;
  border-radius: 6px;
}

/* Robot */
.topRobot {
  float: left;
  vertical-align: middle;
}
.robot-container {
  display: flex;
  align-items: center;
}

.centered {
  text-align: center;
  justify-content: center;
}

/* Speech Bubbles */

/* (A) SPEECH BOX */
.speech {
  margin-right: 50px;
  /* (A1) FONT */
  font-size: .8em;
  /* (A2) COLORS */
  color: #fff;
  background: #0d3685;
  /* (A3) DIMENSIONS + POSITION */
  position: relative;
  padding: 20px;
  border-radius: 12px;
  box-sizing: border-box;
  /*margin: 50px auto;*/
  /*max-width: 320px;*/
}

.speech-query-page {
  margin-top: 2vh;
  font-size: 1em;
}

/* (B) ADD SPEECH "CALLOUT TAIL" */
/* (B1) USE ::AFTER TO CREATE THE "TAIL" */
.speech::after {
  /* (B1-1) ATTACH TRANSPARENT BORDERS */
  content: "";
  border: 20px solid transparent;
  /* (B1-2) NECESSARY TO POSITION THE "TAIL" */
  position: absolute;
}
/* (C2) LEFT */
.left.speech::after {
  /* (C2-1) LEFT TRIANGLE */
  border-right-color: #0d3685;
  border-left: 0;
  /* (C2-2) POSITION AT LEFT */
  left: -19px; top: 50%;
  margin-top: -20px;
}

* {
  font-family: arial, sans-serif;
  box-sizing: border-box;
}

.wrapper {
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto; /* Prevents scrollbars */
}

/* WIDGET */
.widget-wrap {
  max-width: 500px;
  padding: 30px;
  border-radius: 20px;
  background: rgba(255, 255, 255, 0.4);
}

/* SVG */
#talk {
  width: 100%; height:120px;
  background-image: url('data:image/svg+xml;utf8,<svg viewBox="0 0 512 512" width="100" xmlns="http://www.w3.org/2000/svg"><path d="M448 0H64C28.7 0 0 28.7 0 64v288c0 35.3 28.7 64 64 64h96v84c0 9.8 11.2 15.5 19.1 9.7L304 416h144c35.3 0 64-28.7 64-64V64c0-35.3-28.7-64-64-64z" /></svg>');
  background-repeat: no-repeat;
  background-position: center;
}

.confirm-button {
  margin: 0;
  background-color: #4caf50;
}

.confirm-button:hover {
  background-color: #ffcc00;
  color: black;
}

.clear-btn:hover {
  background-color: #ffcc00;
  color: black;
}

.user-bar span {
  font-weight: bold;
  color: white;
}

.user-bar button {
  color: white;
  border: none;
  padding: 8px 12px;
  cursor: pointer;
}

/* user bar */
.user-bar {
  background: #000 !important;
  width: 100% !important;
  position: absolute;
  vertical-align: middle;
  line-height: 2em;
  max-height: 2em;
  font-size: .7em;
}
.user-buttons span {
  padding-left: 10px;
  padding-right: 10px;
  width: 100%;
}
.user-buttons {
  width: 100% !important;
}
.credits {
  margin-left: auto;
  margin-right:auto;
  vertical-align: middle;
}

.whybotsreportloading {
  font-style: italic;
}

.wave { /* for the loading */
  width: 7px;
  height: 100px;
  background: linear-gradient(45deg, #4caf50, #fff);
  margin: 20px;
  animation: wave 1s linear infinite;
  border-radius: 20px;
}
.wave:nth-child(2) {
  animation-delay: 0.1s;
}
.wave:nth-child(3) {
  animation-delay: 0.2s;
}
.wave:nth-child(4) {
  animation-delay: 0.3s;
}
.wave:nth-child(5) {
  animation-delay: 0.4s;
}
.wave:nth-child(6) {
  animation-delay: 0.5s;
}
.wave:nth-child(7) {
  animation-delay: 0.6s;
}
.wave:nth-child(8) {
  animation-delay: 0.7s;
}
.wave:nth-child(9) {
  animation-delay: 0.8s;
}
.wave:nth-child(10) {
  animation-delay: 0.9s;
}

@keyframes wave {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

.loading-center {
  display: flex;
}

.headerlink a {
  color: white;
  text-decoration: none;
}
.headerlink a:hover {
  color: white;
  text-decoration: underline;
}

/* buttons */
.loading-button, .loading-button:hover{
  background-color: grey !important;
  color: black;
  transform: none;
  box-shadow: none;
}

.buttonSpan {
  margin: 0 0 0 0px !important;
}

.history-grouping {
  font-size: 150% !important;
  margin: 15px !important;
}

/* For mobile and other devices below */
@media screen and (max-width: 1200px) {
  .aside {
    max-height: inherit !important;
    overflow: auto !important;
  }
  .history {
    margin-top: 35px;
  }
  .history-container{
    overflow: hidden;
    height: 95vh !important;
  }
  .whybotsreporttitle {
    margin-left: 2.5vw !important;
  }
  .output-container {
    float: none;
    width: 60vw;
    margin-top: 20px !important;
    /* height: 30vh !important; */
    position: relative;
  }
  .report-output {
    float: none;
    margin: 20px;
    display: block;
    width: 55vw;
    margin-top: 10vh;
  }
  div.form-row label {
    max-width: 29% !important;
    font-size: 6pt !important;
    white-space: pre-wrap;
    display: inline-block;
  }
  .sidebar {
    width: 60vw;
    height: 50vh;
  }
  textarea, input {
    padding: 2px;
    font-size: 1em !important;
    width: 60vw !important;
    height: 7vh !important;
    margin: 0 auto;
    display: block;
    text-align: center;
    border: 1px solid #ccc;
    border-radius: 6px;
  }
  .title-input { 
    height: 3vh !important;
  }
  .additional-input { 
    height: 8ch !important;
  }
  .form-input-container {
    height: 45vh !important;
  }
}

@media screen and (max-width: 750px) {
  /* pretty sure this makes the whole page wrap into 1 column */
  .report-output {
    float: none !important;
    margin: 5px;
    margin-right: 5px !important;
    margin-top: 50px !important;
    width: 55vw;
  }
  textarea, input {
    width: 65vw !important;
  }
  .hero-title {
    padding: 10px !important;
  }
  .server-response {
    padding: 5px !important;
    width: 100vw !important;
  }
  .server-response label {
    font-size: 2em !important;
  }
  .form-input-container {
    max-width: inherit !important;
    max-height: 35vh !important;
  }
  .form-row {
    align-items: center;
    margin-bottom: 10px; /* Adjust this value based on your design */
  }
  div.form-row label {
    max-width: 100vw !important;
    font-size: .5em !important;
    white-space: pre-wrap;
    display: inline-block;
  }
  div.form-row span {
    font-size: .6em !important;
    width: 100vw !important;
  }
  .user-bar {
    position: relative !important;
    display: relative;
    width: 100vw !important;
  }
  .output-container {
    width: 60vw !important;
    height: 25vh !important;
  }
  .speech {
    font-size: .4em !important;
  }
  .aside, .history-container, .history {
    width: 30vw !important;
    overflow: hidden !important;
  }
  .robot-container img {
    width: 150px;
    height: 150px;
  }
}