html, body {
  height: 100vh;
  width: 98vw;
  padding: 0;
  display: table;
  margin: auto;
}

.container {
  display: flex;
  flex-direction: column;
  height: 80vh;
}

.content {
  flex: 1;
}

div.account-page form input {
  width: 200px;
}

.front-page {
  padding: 40px;
  text-align: center;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 800px;
  margin: 40px auto;
}

.robot-container-front-page {
  display: flex;
  align-items: center;
  justify-content: center;
}
  
.front-page h1 {
  font-size: 36px;
  color: #4a4a4a;
  margin-bottom: 20px;
}
  
.front-page p {
  font-size: 18px;
  color: #7a7a7a;
  line-height: 1.5;
}
  
/* styles.css */

.Front {
  font-family: Arial, sans-serif;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100vw !important;
  margin: 5px !important;
}

header {
  text-align: center;
  margin: 3px;
}

span.hero {
  text-align: center;
}

span.hero button {
  background-color: white;
  color: #0070ba;
}

.hero-title {
  margin-top: 0px;
  background-color: #008dda;
  font-size: 1.8em;
  padding: 50px;
  color: white;
  font-weight: normal;
}

.hero-title-2 {
  margin-top: 20px;
  background-color: #0d3685;
  font-size: 2.5em;
  padding: 50px;
  color: white;
}

.hero-description {
  font-weight: bold;
  font-size: 1.2em;
}

.features-section, .how-it-works-pricing-data{
  /*padding: 50px 0;*/
  text-align: center;
}

.cta,
.testimonials,
.features,
.journey,
footer,
.how-it-works-pricing-item {
  text-align: center;
}

footer {
  display: inline-block;
  padding: 10px;
  font-style: italic;
}

.cta {
  padding: 20px;
}
.section-title {
  font-size: 2em;
  margin-bottom: 20px;
}

.section-image {
  max-width: 100%;
  height: auto;
}

.features-wrapper, .journey-wrapper, .how-it-works-pricing-data-wrapper {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.feature-item, .journey-item, .how-it-works-pricing-data-item{
  flex: 0 1 calc(25% - 20px); /* this means that the item will take up 25% of the container width minus the margin */
  margin: 10px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  transition: all 0.3s ease-in-out;
}

.feature-item:hover, .journey-item:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 1);
  transform: translateY(-5px);
}

.feature-item img {
  width: 200px;
}
.journey-item img {
  width: 200px;
}

form.contact {
  padding: 20px;
}

.pricing-header {
  padding: 20px;
  width: 50%;
  margin-left: auto;
  margin-right: auto;
}

.learn-more-image {
  padding-left: 20px;
}

.panel-left {
  float: left;
  vertical-align: middle;
}

.learn-more-section, .account-page{
  text-align: center;
  width:1024px !important;
  margin-left: auto;
  margin-right: auto;
}
ol {
  margin-block-start: 0em;
  margin-block-end: 0em;
  padding: 10px;
}

button {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
  background-color: #fff;
  color: black;
  border-color: black;
  padding: 12px 24px;
  text-align: center;
  text-decoration: none; /* No underline */
  display: inline-block;
  font-size: .8em;
  font-weight: bold;
  margin: 4px 2px;
  transition: all 0.3s ease 0s;
  cursor: pointer;
  border-radius: 5px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.2);
}

button:hover {
  background-color: #1a73e8;
  color: #fff;
  transform: translateY(-3px); /* Move the button up a bit */
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.2);
}

.signup-button button {
  color: #fff;
  background-color: #1a73e8;
}

.signup-button button:hover {
  border: 2px solid black;
  background-color: #FFA108;
}

.gold-button button {
  background-color: #FFA108;
  color: black;
}
.gold-button button:hover {
  background-color: #1a73e8;
  color: white;
}

.fa-coins {
  color: #FFA108;
}

@media screen and (max-width: 768px) {
  .hero-title {
    font-size: inherit !important;
    padding: 10px !important;
  }
  .Front {
    width: 120vw !important;
  }
}

/* ugh these should prob be on query.css */
@media screen and (max-width: 480px) {
  .hero-title {
    font-size: inherit !important;
    padding: 10px !important;
  }
  .server-response {
    padding: 5px !important;
    display: block;
    width: 100vw !important;
  }
  .topRobot {
    width: 50vw !important;
    height: 20vh !important;
  }
}
